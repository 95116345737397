@font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-Light.eot);
  src: url(/fonts/GothamPro/GothamPro-Light.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-Light.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-Light.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-Light.svg#GothamPro-Light) format('svg'),
    url(/fonts/GothamPro/GothamPro-Light.eot?#iefix) format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-LightItalic.eot);
  src: url(/fonts/GothamPro/GothamPro-LightItalic.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-LightItalic.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-LightItalic.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-LightItalic.svg#GothamPro-LightItalic) format('svg'),
    url(/fonts/GothamPro/GothamPro-LightItalic.eot?#iefix) format('embedded-opentype');
  font-weight: 100;
  font-style: italic;
} */

@font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro.eot);
  src: url(/fonts/GothamPro/GothamPro.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro.svg#GothamPro) format('svg'),
    url(/fonts/GothamPro/GothamPro.eot?#iefix) format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-Italic.eot);
  src: url(/fonts/GothamPro/GothamPro-Italic.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-Italic.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-Italic.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-Italic.svg#GothamPro-Italic) format('svg'),
    url(/fonts/GothamPro/GothamPro-Italic.eot?#iefix) format('embedded-opentype');
  font-weight: 200;
  font-style: italic;
} */

@font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-Medium.eot);
  src: url(/fonts/GothamPro/GothamPro-Medium.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-Medium.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-Medium.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-Medium.svg#GothamPro-Medium) format('svg'),
    url(/fonts/GothamPro/GothamPro-Medium.eot?#iefix) format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-MediumItalic.eot);
  src: url(/fonts/GothamPro/GothamPro-MediumItalic.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-MediumItalic.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-MediumItalic.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-MediumItalic.svg#GothamPro-MediumItalic) format('svg'),
    url(/fonts/GothamPro/GothamPro-MediumItalic.eot?#iefix) format('embedded-opentype');
  font-weight: 400;
  font-style: italic;
} */

@font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-Bold.eot);
  src: url(/fonts/GothamPro/GothamPro-Bold.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-Bold.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-Bold.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-Bold.svg#GothamPro-Bold) format('svg'),
    url(/fonts/GothamPro/GothamPro-Bold.eot?#iefix) format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-BoldItalic.eot);
  src: url(/fonts/GothamPro/GothamPro-BoldItalic.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-BoldItalic.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-BoldItalic.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-BoldItalic.svg#GothamPro-BoldItalic) format('svg'),
    url(/fonts/GothamPro/GothamPro-BoldItalic.eot?#iefix) format('embedded-opentype');
  font-weight: 600;
  font-style: italic;
} */

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-Black.eot);
  src: url(/fonts/GothamPro/GothamPro-Black.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-Black.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-Black.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-Black.svg#GothamPro-Black) format('svg'),
    url(/fonts/GothamPro/GothamPro-Black.eot?#iefix) format('embedded-opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: GothamPro;
  src: url(/fonts/GothamPro/GothamPro-BlackItalic.eot);
  src: url(/fonts/GothamPro/GothamPro-BlackItalic.woff2) format('woff2'),
    url(/fonts/GothamPro/GothamPro-BlackItalic.woff) format('woff'),
    url(/fonts/GothamPro/GothamPro-BlackItalic.ttf) format('truetype'),
    url(/fonts/GothamPro/GothamPro-BlackItalic.svg#GothamPro-BlackItalic) format('svg'),
    url(/fonts/GothamPro/GothamPro-BlackItalic.eot?#iefix) format('embedded-opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
} */
